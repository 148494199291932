export class CookieService {
  static setCookie(name: string, value: string, path: string = '/'): void {
    document.cookie = `${name}=${encodeURIComponent(value)}; path=${path}`;
  }

  static getCookie(name: string): string | null {
    const nameEQ = `${name}=`;
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();

      if (cookie.indexOf(nameEQ) === 0) {
        return decodeURIComponent(cookie.substring(nameEQ.length));
      }
    }

    return null;
  }

  static deleteCookie(name: string, path: string = '/'): void {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}`;
  }

  static hasCookie(name: string): boolean {
    return this.getCookie(name) !== null;
  }
}

export default CookieService;
