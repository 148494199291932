export const theme = {
  primaryColor: '#003342',
  secondaryColor: '#fcffe8',
  lightGreenColor: '#C0D32C',
  textPrimaryColor: '#1d1617',
  textSecondaryColor: '#4f4f4f',
  success: '#75aa12',
  error: '#ea2a2a',
  fontFamily: "'Montserrat', sans-serif",
  buttonBorderRadius: 12,
  fontSize16: 16,
  whiteColor: '#ffffff',
  fontFamilySecond: "'Lato', sans-serif",
  fontSizeSmall: 12,
  fontSize18: 18,
  fontSizeL: 26,
  fontSizeML: 28,
  fontSizeXL: 32,
  fontSizeXXXL: 48,
  fontWeightRegular: 400,
  fontWeightSemibold: 600,
  fontWeightBold: 700,
  lineHeightTight: 1,
  lineHeightMedium: 1.25,
  lineHeightLarge: 1.5,
  letterSpacingNarrow: -0.02,
  categoryCardRadius: 24,
};
