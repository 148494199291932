export const feedbackMockData = [
  {
    id: 'unique-id-1',
    title: 'Товар',
    image: 'feedback-avatar',
    authorName: 'Карина',
    authorSurname: 'Лі',
    review:
      'Lorem ipsum dolor sit amet consectetur. Eu nec elementum nisl dolor. ' +
      'Elit orci morbi purus nunc massa curabitur dictumst at ullamcorper. ' +
      'Sed in nibh ante enim cursus pretium. Ac sit sodales a imperdiet libero enim vitae.',
    rating: 5,
    createdAt: '2024-11-01T12:00:00Z',
  },
  {
    id: 'unique-id-2',
    title: 'Товар',
    image: 'feedback-avatar',
    authorName: 'Карина',
    authorSurname: 'Лі',
    review:
      'Lorem ipsum dolor sit amet consectetur. Eu nec elementum nisl dolor. ' +
      'Elit orci morbi purus nunc massa curabitur dictumst at ullamcorper. ' +
      'Sed in nibh ante enim cursus pretium. Ac sit sodales a imperdiet libero enim vitae.',
    rating: 5,
    createdAt: '2024-11-01T12:00:00Z',
  },
  {
    id: 'unique-id-3',
    title: 'Товар',
    image: 'feedback-avatar',
    authorName: 'Карина',
    authorSurname: 'Лі',
    review:
      'Lorem ipsum dolor sit amet consectetur. Eu nec elementum nisl dolor. ' +
      'Elit orci morbi purus nunc massa curabitur dictumst at ullamcorper. ' +
      'Sed in nibh ante enim cursus pretium. Ac sit sodales a imperdiet libero enim vitae.',
    rating: 5,
    createdAt: '2024-11-01T12:00:00Z',
  },
  {
    id: 'unique-id-4',
    title: 'Товар',
    image: 'feedback-avatar',
    authorName: 'Карина',
    authorSurname: 'Лі',
    review:
      'Lorem ipsum dolor sit amet consectetur. Eu nec elementum nisl dolor. ' +
      'Elit orci morbi purus nunc massa curabitur dictumst at ullamcorper. ' +
      'Sed in nibh ante enim cursus pretium. Ac sit sodales a imperdiet libero enim vitae.',
    rating: 5,
    createdAt: '2024-11-01T12:00:00Z',
  },
  {
    id: 'unique-id-5',
    title: 'Товар',
    image: 'feedback-avatar',
    authorName: 'Карина',
    authorSurname: 'Лі',
    review:
      'Lorem ipsum dolor sit amet consectetur. Eu nec elementum nisl dolor. ' +
      'Elit orci morbi purus nunc massa curabitur dictumst at ullamcorper. ' +
      'Sed in nibh ante enim cursus pretium. Ac sit sodales a imperdiet libero enim vitae.',
    rating: 5,
    createdAt: '2024-11-01T12:00:00Z',
  },
];
